import CreateOrder from "../components/CreateOrder";
import UpdateOrder from "../components/UpdateOerder";
import InfoOrder from "../components/InfoOrder";
import AssignOrder from "../components/AssignOrder";
import AssignIntegra from "../components/AssignIntegra";
import ChangeStatus from "../components/ChangeStatus";

export const MODALS = [
  { key: "isRight", Component: CreateOrder },
  { key: "isUpdate", Component: UpdateOrder },
  { key: "isInfoOrder", Component: InfoOrder },
  { key: "isAssignOrder", Component: AssignOrder },
  { key: "isAssignIntegra", Component: AssignIntegra },
  { key: "isChangeStatus", Component: ChangeStatus },
];

export interface ModalState {
  isRight: boolean;
  isUpdate: boolean;
  isInfoOrder: boolean;
  isAssignOrder: boolean;
  isAssignIntegra: boolean;
  isChangeStatus: boolean;
  selectedOrderId: string | null;
  selectedDate: string | null;
}

export const modalKeys: Array<keyof ModalState> = [
  "isRight",
  "isUpdate",
  "isInfoOrder",
  "isAssignOrder",
  "isAssignIntegra",
  "isChangeStatus",
];