import React, { useMemo } from "react";

const getInitials = (name: string) => {
  const nameParts = name.split(/[\s_]+/);
  const initials = nameParts.map((part) => part[0]?.toUpperCase()).join("");
  return initials.slice(0, 2);
};

const UserListAvatar = ({ responsible_users }: { responsible_users: any[] }) => {
  const MAX_DISPLAY = 4;

  const listUser = useMemo(() => {
    return responsible_users
      .slice(MAX_DISPLAY)
      .map((user) => user.name)
      .join(", ");
  }, [responsible_users]);


  const avatars = useMemo(() => {
    return responsible_users.slice(0, MAX_DISPLAY).map((user, idx) => (
      <div
        key={idx}
        className="position-relative"
        style={{
          height: 30,
          left: `-${idx * 12}px`,
          zIndex: MAX_DISPLAY + idx,
        }}
      >
        <img
          src={`https://placehold.co/200/5156BE/white/?text=${getInitials(user.name)}&font=lato`}
          alt={user.name}
          title={user.name}
          className="rounded-circle avatar-sm"
          style={{
            width: 30,
            height: 30,
            border: "2px solid white",
          }}
        />
        <span className="user-status"></span>
      </div>
    ));
  }, [responsible_users]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      {avatars}
      {responsible_users.length > MAX_DISPLAY && (
        <div className="position-relative">
          <div
            className="position-absolute bg-secondary text-white d-flex align-items-center justify-content-center rounded-circle"
            style={{
              left: `-${MAX_DISPLAY * 12}px`,
              top: -15,
              zIndex: 90,
              width: 30,
              height: 30,
              border: "2px solid white",
              cursor: "default",
            }}
            title={listUser}
          >
            +{responsible_users.length - MAX_DISPLAY}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserListAvatar;