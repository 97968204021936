import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import { TrafficOrderService } from "../../../services/traffic_order.service";
import usePermissions from "../../../hooks/usePermissions";
import classnames from "classnames";
import {toast} from "react-toastify";
import TabPaneContent from "./components/TabPaneContent";
import {modalKeys, MODALS, ModalState} from "./constants/modals";
import {useDispatch, useSelector} from "react-redux";
import {clearTrafficOrdersNotActive, getTrafficOrdersNotActive} from "../../../store/trafficManagement/actions";
import {parseQueryString} from "../../../helpers/parse_query_string";
import { useFilter } from "../../../hooks/useFilter";

const TrafficOrders: React.FC = () => {
  const {hasPermission} = usePermissions();

  if(!hasPermission('view-traffic-orders')){
    window.location.href = "/dashboard";
  }

  const dispatch = useDispatch();
  const { filter, setFilter } = useFilter();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState("1");
  const [modalState, setModalState] = useState<ModalState>({
    isRight: false,
    isUpdate: false,
    isInfoOrder: false,
    isAssignOrder: false,
    isAssignIntegra: false,
    isChangeStatus: false,
    selectedOrderId: null,
    selectedDate: null
  });

  const { data } = useSelector((state: any) => state.TrafficManagement);
  const {trafficOrdersNotActive} = data;

  useEffect(() => {
    dispatch(getTrafficOrdersNotActive("", filter))
    return () => {
      dispatch(clearTrafficOrdersNotActive())
    }
  }, [dispatch]);

  useEffect(() => {
    const queryObject = parseQueryString(trafficOrdersNotActive?.pagination?.next_page || "");
    setFilter(queryObject);
  }, [trafficOrdersNotActive, setFilter]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries({queryKey: ["keyTrafficOrders"]});
      queryClient.removeQueries({queryKey: ["keyTrafficOrdersWaiting"]});
    };
  }, [queryClient]);


  const toggleModal = (key: keyof ModalState, id?: string | null, date?: string | null) => {
    setModalState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
      selectedOrderId: id || null,
      selectedDate: date || null,
    }));
  };

  const {
    data: trafficOrders,
    isSuccess,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["keyTrafficOrders"],
    queryFn:() => TrafficOrderService.getTrafficOrders(),
    retry: false,
  });

  const {
    data: trafficOrdersWaiting,
    isSuccess: isSuccessOrdersWaiting,
    isLoading: isLoadingOrdersWaiting,
    isError: isErrorOrdersWaiting,
  } = useQuery({
    queryKey: ["keyTrafficOrdersWaiting"],
    queryFn: () => TrafficOrderService.getTrafficOrdersWaiting(),
    retry: false,
  });


  const toggle1 = (tab: any) => {
    if (activeTab !== tab) { setActiveTab(tab) }
  };

  const closeAllToast = () => { toast.dismiss(); };

  async function loadMore() {
    if(trafficOrdersNotActive?.pagination?.next_page){
      dispatch(getTrafficOrdersNotActive("", filter))
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Dashboard" breadcrumbItem="Traffic Orders" />
        <Row className="align-items-center">
          <Col md={12} className="d-sm-flex justify-content-between">
            <div className="align-items-center justify-content-start gap-2 mb-3">
              {hasPermission('traffic-orders-close-all-toast') &&
                <Button onClick={() => closeAllToast()} className="btn btn-light"
                        disabled={!hasPermission('traffic-orders-close-all-toast')}>
                  Read all notify
                </Button>
              }
            </div>
            <div className="align-items-center justify-content-end gap-2 mb-3">
              {hasPermission('add-traffic-orders') &&
                <Button onClick={() => toggleModal("isRight")} className="btn btn-light"
                        disabled={!hasPermission('add-traffic-orders')}>
                  <i className="bx bx-plus me-1"></i> Add Order
                </Button>
              }
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Nav pills className="navtab-bg nav-justified mb-4">
              {["1", "2", "3"].map((tabId, index) => {
                const labels = ["Active", "Waiting", "Not Active"];
                return (
                  <NavItem key={tabId}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === tabId })}
                      onClick={() => toggle1(tabId)}
                    >
                      {labels[index]}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>

            <TabContent activeTab={activeTab} className="p-3 text-muted">
              {["1", "2", "3"].map((tabId, index) => {
                const tabData = [
                  {
                    isLoading: isLoading,
                    isError: isError,
                    isSuccess: isSuccess,
                    trafficOrders: trafficOrders,
                    customActions: {},
                  },
                  {
                    isLoading: isLoadingOrdersWaiting,
                    isError: isErrorOrdersWaiting,
                    isSuccess: isSuccessOrdersWaiting,
                    trafficOrders: trafficOrdersWaiting,
                    customActions: { toggleChangeStatus: () => {} },
                  },
                  {
                    isLoading: trafficOrdersNotActive.loading,
                    isError: trafficOrdersNotActive.isError,
                    isSuccess: trafficOrdersNotActive.loaded,
                    trafficOrders: trafficOrdersNotActive,
                    customActions: {
                      toggleAssignOrder: () => {},
                      toggleAssignIntegra: () => {},
                      toggleUpdate: () => {},
                      toggleChangeStatus: () => {},
                      loadMore,
                    },
                  },
                ];

                return (
                  <TabPane tabId={tabId} key={tabId}>
                    <TabPaneContent
                      activeTabId={tabId}
                      {...tabData[index]}
                      toggleModal={(key, id, date) => {
                        if (modalKeys.includes(key as keyof ModalState)) {
                          toggleModal(key as keyof ModalState, id, date);
                        }
                      }}
                    />
                  </TabPane>
                );
              })}
            </TabContent>
          </Col>
        </Row>

        {MODALS.map(({ key, Component }) =>
          modalState[key as keyof ModalState] ? (
            <Component
              key={key}
              isOpen={!!modalState[key as keyof ModalState]}
              toggle={() => toggleModal(key as keyof ModalState)}
              id={modalState.selectedOrderId || ""}
              date={modalState.selectedDate || ""}
            />
          ) : null
        )}
      </Container>
    </div>
  );
};

export default TrafficOrders;