export const ORDER_STATUSES = [
  { value: 1, label: "Active", colorClass: "bg-success", iconClass: "bx-check-double" },
  { value: 2, label: "Pause", colorClass: "bg-warning", iconClass: "bx-error" },
  { value: 3, label: "Stop", colorClass: "bg-danger", iconClass: "bx-block" },
  { value: 4, label: "Waiting", colorClass: "bg-primary", iconClass: "bx-smile" },
  { value: 5, label: "Not Active", colorClass: "bg-secondary", iconClass: "bx-block" },
];

export const filteredStatuses = ORDER_STATUSES.reduce((acc, { value, label }) => {
  if (value !== 4 && value !== 5) {
    acc.push({ value, label });
  }
  return acc;
}, [] as { value: number; label: string }[]);

export const pay_methods = [
  {"value": 1, "label": "cpa"},
  {"value": 2, "label": "cpl"},
  {"value": 3, "label": "crg"},
];

export const buy_methods = [
  {"value": 1, "label": "Buy"},
  {"value": 2, "label": "Resale"},
];