import React, {useEffect} from "react";
import * as Yup from "yup";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { Form, ModalBody, ModalHeader, Modal, Row, Col } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { TrafficOrderService } from "../../../../services/traffic_order.service";
import {filteredStatuses, ORDER_STATUSES} from "../constants/order";

interface IChangeStatus {
  isOpen: boolean;
  toggle: () => void;
  id: string;
  date: string;
}

const validationSchema = Yup.object({
  status: Yup.number().required("At least one status must be selected"),
});

interface StatusOrder {
  status: number;
}

const ChangeStatus: React.FC<IChangeStatus> = ({ isOpen, toggle, id, date }) => {
  const queryClient = useQueryClient();

  const {
    data: StatusTrafficOrder,
    isLoading: isLoadingStatusTrafficOrder
  } = useQuery({
    queryKey: ["keyStatusTrafficOrder", +id, date],
    queryFn: () => TrafficOrderService.getStatusTrafficOrder(+id, date),
  });


  const {
    mutate: updateStatusTrafficOrder,
    isPending:isPendingUpdateStatusTrafficOrder,
    isSuccess: isSuccessUpdateStatusTrafficOrder
  } = useMutation({
    mutationFn: (data: StatusOrder) => TrafficOrderService.updateStatusTrafficOrder(data, +id),
    mutationKey: ["keyUpdateStatusTrafficOrder", id],
  });

  const formik = useFormik({
    initialValues: {
      status: StatusTrafficOrder?.status || null,
    },
    onSubmit: (values) => {
      updateStatusTrafficOrder(values)
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { submitForm, setFieldValue, values, touched, errors, isValid } = formik;

  useEffect(() => {
    if(isSuccessUpdateStatusTrafficOrder){
      toggle();
      queryClient.invalidateQueries({ queryKey: ['keyTrafficOrders'] });
    }
  }, [isSuccessUpdateStatusTrafficOrder]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Status Order {id}</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col xs={12}>
              <div className="mb-4">
                <label>Select status</label>
                <Select
                  name="status"
                  options={ORDER_STATUSES
                    .filter(({ value }) => ![4, 5].includes(value))
                    .map(({ value, label }) => ({ value, label })) || []}
                  placeholder="Select status..."
                  isClearable
                  isLoading={isLoadingStatusTrafficOrder}
                  onChange={(option: any) => setFieldValue("status", option ? option.value : null)}
                  value={ORDER_STATUSES?.find((i: any) => i.value === values.status) || null}
                />
                {touched.status && errors.status && (
                  <div className="text-danger">{errors.status as string}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <button
                type="button"
                className="btn btn-success save-user"
                onClick={submitForm}
                disabled={!isValid || isPendingUpdateStatusTrafficOrder}
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ChangeStatus;