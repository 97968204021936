import React from "react";
import { FilterProvider } from "../../../providers/FilterProvider";
import TrafficOrders from "./trafficOrders";

const TrafficOrdersPage: React.FC = () => (
  <FilterProvider>
    <TrafficOrders />
  </FilterProvider>
);

export default TrafficOrdersPage;