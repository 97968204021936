import { call, put, takeEvery } from "redux-saga/effects";
import { TrafficOrdersTypes } from "./actionTypes";

import {
  getTrafficOrdersNotActiveSuccess,
  getTrafficOrdersNotActiveFail,
} from "./actions";

import {
  fetchTrafficOrdersNotActive as fetchTrafficOrdersNotActiveApi
} from "../../helpers/backend_helper";


function* fetchTrafficOrdersNotActive({next_page, filter}: any) {
  try {
    const response: Promise<any> = yield call(fetchTrafficOrdersNotActiveApi, next_page, filter);
    yield put(getTrafficOrdersNotActiveSuccess(response));
  } catch (error) {
    console.log("error", error)
    yield put(getTrafficOrdersNotActiveFail(error));
  }
}

function* TrafficManagementSaga() {
  yield takeEvery(TrafficOrdersTypes.GET_TRAFFIC_ORDERS_NOT_ACTIVE, fetchTrafficOrdersNotActive);
}

export default TrafficManagementSaga;