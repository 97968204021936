import { TrafficOrdersTypes, TrafficOrdersState } from './actionTypes';

const initialState: TrafficOrdersState = {
  data: {
    trafficOrdersNotActive: {
      data: [],
      pagination: {},
      loading: false,
      loaded: false,
      error: {},
      isError: false
    },
  },
};

const trafficOrdersReducer = (state = initialState, action: any): TrafficOrdersState => {
  switch (action.type) {
    case TrafficOrdersTypes.GET_TRAFFIC_ORDERS_NOT_ACTIVE:
      return {
        ...state,
        data: {
          ...state.data,
          trafficOrdersNotActive: {
            ...state.data.trafficOrdersNotActive,
            loading: true,
            loaded: false,
            error: {},
            isError: false,
          },
        },
      };

    case TrafficOrdersTypes.GET_TRAFFIC_ORDERS_NOT_ACTIVE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          trafficOrdersNotActive: {
            ...state.data.trafficOrdersNotActive,
            data: [
              ...state.data.trafficOrdersNotActive.data,
              ...action.payload.data,
            ],
            pagination: action.payload.pagination,
            loading: false,
            loaded: true,
            error: {},
            isError: false,
          },
        },
      };

    case TrafficOrdersTypes.TRAFFIC_ORDERS_NOT_ACTIVE_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          trafficOrdersNotActive: {
            ...state.data.trafficOrdersNotActive,
            loading: false,
            loaded: false,
            error: action.payload.error,
            isError: true,
          },
        },
      };

    case TrafficOrdersTypes.CLEAR_TRAFFIC_ORDERS_NOT_ACTIVE:
      return {
        ...state,
        data: {
          ...state.data,
          trafficOrdersNotActive: {
            data: [],
            pagination: {},
            loading: false,
            loaded: false,
            error: {},
            isError: false,
          },
        },
      };

    default:
      return state;
  }
};

export default trafficOrdersReducer;