import {bool} from "yup";

export enum TrafficOrdersTypes {
    GET_TRAFFIC_ORDERS_NOT_ACTIVE = "@@TrafficOrders/GET_NOT_ACTIVE",
    GET_TRAFFIC_ORDERS_NOT_ACTIVE_SUCCESS = "@@TrafficOrders/GET_NOT_ACTIVE_SUCCESS",
    CLEAR_TRAFFIC_ORDERS_NOT_ACTIVE = "@@TrafficOrders/CLEAR_NOT_ACTIVE",
    TRAFFIC_ORDERS_NOT_ACTIVE_FAIL = "@@TrafficOrders/NOT_ACTIVE_FAIL",
}

interface TrafficOrdersNotActive {
    data: Array<any>,
    pagination: Object,
    loading: boolean,
    loaded: boolean,
    error : Object,
    isError: boolean,
}

interface DataTrafficOrders {
    trafficOrdersNotActive: TrafficOrdersNotActive
}

export interface TrafficOrdersState {
    data : DataTrafficOrders;
}
