import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess } from "./actions";

import {
  postJwtLogin,
} from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }: any) {
  try {
    const response: Promise<any> = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    });

    yield put(loginSuccess(response));

    const authUser = {
      // @ts-ignore
      "token_type": response?.token_type,
      // @ts-ignore
      "token": response?.token,
      // @ts-ignore
      "roles": response?.roles,
      // @ts-ignore
      "user_id": response?.user_id
    };
    localStorage.setItem("authUser", JSON.stringify(authUser));

    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history, unsubscribeFromChannels } }: any) {
  try {
    if (unsubscribeFromChannels) { unsubscribeFromChannels(); }
    localStorage.removeItem("authUser");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}



function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser);
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser);
}

export default authSaga;
