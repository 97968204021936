import React, { useState, useEffect, useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type MultiDatePickerProps = {
  initialDates?: Date[];
  onDatesChange?: (dates: Date[]) => void;
  isDisabled?: boolean;
};

const MultiDatePicker: React.FC<MultiDatePickerProps> = ({initialDates = [], onDatesChange, isDisabled = false,}) => {

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [rangeStartDate, setRangeStartDate] = useState<Date | null>(null);
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);

  useEffect(() => {
    if(initialDates.length > 0) {
      setSelectedDates(initialDates);
    }
  }, [initialDates]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (!date || isDisabled) return;

      if (isShiftPressed && rangeStartDate) {
        const start = new Date(
          Math.min(rangeStartDate.getTime(), date.getTime())
        );
        const end = new Date(
          Math.max(rangeStartDate.getTime(), date.getTime())
        );

        const rangeDates: Date[] = [];
        let currentDate = new Date(start);

        while (currentDate <= end) {
          rangeDates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }

        const uniqueDates = Array.from(
          new Set([
            ...selectedDates,
            ...rangeDates.filter(
              (newDate) =>
                !selectedDates.some(
                  (selectedDate) =>
                    selectedDate.toDateString() === newDate.toDateString()
                )
            ),
          ])
        );

        setSelectedDates(uniqueDates);
        setRangeStartDate(null);
        if (onDatesChange) onDatesChange(uniqueDates);
      } else {
        const isAlreadySelected = selectedDates.some(
          (selectedDate) =>
            selectedDate.toDateString() === date.toDateString()
        );

        if (isAlreadySelected) {
          const updatedDates = selectedDates.filter(
            (selectedDate) =>
              selectedDate.toDateString() !== date.toDateString()
          );
          setSelectedDates(updatedDates);
          if (onDatesChange) onDatesChange(updatedDates);
        } else {
          const newDates = [...selectedDates, date];
          setSelectedDates(newDates);
          setRangeStartDate(date);
          if (onDatesChange) onDatesChange(newDates);
        }
      }
    },
    [isShiftPressed, rangeStartDate, selectedDates, onDatesChange]
  );

  return (
    <div>
      <DatePicker
        selected={null}
        onChange={(date) => handleDateChange(date as Date)}
        inline
        highlightDates={selectedDates}
        minDate={new Date()}
        disabled={isDisabled}
        dateFormat={"Y.m.d"}
      />
    </div>
  );
};

export default MultiDatePicker;