import React, {useEffect} from "react";
import {Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {BrokerService} from "../../../../services/broker.service";
import Select from 'react-select';
import NoSearchResult from "../../../../components/Common/NoSearchResult";
import {useDispatch, useSelector} from "react-redux";
import {clearCountries, getCountries} from "../../../../store/countries/actions";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Flatpickr from "react-flatpickr";
import {formatDate} from "../../../../helpers/formatDate";
import {UserService} from "../../../../services/user.service";
import {TrafficOrderService} from "../../../../services/traffic_order.service";
import {buy_methods, pay_methods} from "../../../../constants/traffic_order";
import MultiDatePicker from "../../../../components/Common/MultiDatePicker";
import {CountryBoxService} from "../../../../services/country_box.service";
import moment from "moment/moment";

interface ICreateOrder {
  isOpen: boolean,
  toggle: () => void,
}

const validationSchema = Yup.object({
  broker_id: Yup.number().nullable(),
  buy_method_id: Yup.number().required('Buy method is required'),
  resale: Yup.string().when('buy_method_id', (buy_method_id) => {
    return (buy_method_id[0] === 2)
      ? Yup.string().required('Resale is required')
      : Yup.string().notRequired();
  }),
  user_ids: Yup.array().when('buy_method_id', (buy_method_id) => {
    return (buy_method_id[0] === 1)
      ? Yup.array().min(1, 'At least one team must be selected')
      : Yup.array().notRequired();
  }),
  //geo_ids: Yup.array().min(1, 'At least one country must be selected'),
  geo_ids: Yup.array()
    .nullable()
    .test(
      'geo-or-box',
      'Either geo or geo_box  must be selected',
      function (geo_ids) {
        const { geo_box_id } = this.parent;
        return (
          (Array.isArray(geo_ids) && geo_ids.length > 0) ||
          (geo_box_id !== null && geo_box_id !== undefined)
        );
      }
    ),
  geo_box_id: Yup.number()
    .nullable()
    .test(
      'box-or-geo',
      'Either geo box or geo must be selected',
      function (geo_box_id) {
        const { geo_ids } = this.parent;
        return (
          (geo_box_id !== null && geo_box_id !== undefined) ||
          (Array.isArray(geo_ids) && geo_ids.length > 0)
        );
      }
    ),
  funnel_name: Yup.string().required('Funnel name is required'),
  pay_method_id: Yup.number().required('Buy method is required'),
  payout: Yup.number().required('Payout is required').positive('Payout must be positive'),
  cr: Yup.number().when('pay_method_id', (pay_method_id) => {
    return (pay_method_id[0] === 3)
      ? Yup.number().required('CR is required').positive('Payout must be positive')
      : Yup.number().notRequired();
  }),
  daily_cap: Yup.number().required('Daily Cap is required').positive('Payout must be positive'),
  dates: Yup.array().min(1, 'At least one date must be selected'),
  from_time: Yup.string()
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Start time must be in the format HH:MM and between 00:00 and 23:59')
    .nullable()
    .required('Start time is required'),

  to_time: Yup.string()
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'End time must be in the format HH:MM and between 00:00 and 23:59')
    .nullable()
    .required('End time is required')
});

const CreateOrder = ({isOpen, toggle}: ICreateOrder) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { dataCountries, loading } = useSelector((state: any) => ({
    dataCountries: state.Countries.data || [],
    loading: state.Countries.loading
  }));

  const { data: brokerData, isLoading, isError } = useQuery({
    queryKey: ["keyBrokerList"], queryFn: BrokerService.getBrokerList, staleTime: 60000 * 10
  });

  const { data: buyerData, isLoading: isLoadingBuyer, isError: isErrorBuyer } = useQuery({
    queryKey: ["keyBuyerTeamList"], queryFn: UserService.getBuyerTeamLead
  });

  const {data: CountryBoxes, isLoading: isLoadingCountryBoxes, isError: isErrorCountryBoxes} = useQuery({
    queryKey: ["keyCountryBoxes"], queryFn: CountryBoxService.getCountryBoxes
  })

  const {mutate: createTrafficOrder, data: resultData, isError: isErrorCreateTrafficOrder, isPending, isSuccess: isSuccessCreateTrafficOrder, error, status} = useMutation({
    mutationFn: TrafficOrderService.createTrafficOrder,
    mutationKey: ['keyCrateTrafficOrder']
  });

  useEffect(() => {
    dispatch(getCountries());
    return () => {
      dispatch(clearCountries());
    };
  }, [dispatch]);

  useEffect(() => {
    if(isSuccessCreateTrafficOrder) {
      toggle()
      queryClient.invalidateQueries({ queryKey: ['keyTrafficOrders'] })
    }
  }, [isSuccessCreateTrafficOrder]);

  /*const handleDatesChange = (newDates: Date[], setFieldValue: (field: string, value: any) => void) => {
    const sortedDates = [...newDates].sort((a, b) => a.getTime() - b.getTime());
    const formattedDates = sortedDates.map((date) => date.toLocaleDateString());
    setFieldValue("dates", formattedDates);
  };*/

  const handleDatesChange = (
    newDates: Date[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    const sortedDates = [...newDates]
      .map(date => moment(date).format("YYYY-MM-DD"))
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    setFieldValue("dates", sortedDates);
  };

  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>  Add Traffic Order </OffcanvasHeader>

      {!isLoading ? (
        <OffcanvasBody>
          <Formik
            initialValues={{
              broker_id: null,
              buy_method_id: null,
              resale: '',
              user_ids: [],
              geo_box_id: null,
              geo_ids: [],
              funnel_name: '',
              pay_method_id: null,
              payout: '',
              cr: '',
              dates: [],
              daily_cap: '',
              from_time: "00:01",
              to_time: "23:59",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              createTrafficOrder(values)
            }}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, isValid, errors }) => {
              console.log("values", values)

              return (
                <Form>

                  <div className="mb-4">
                    <label>Select date</label>
                    <MultiDatePicker
                      onDatesChange={(newDates: Date[]) => handleDatesChange(newDates, setFieldValue)}
                    />
                    <ErrorMessage
                      name="dates"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mb-4">
                    <label>Select broker</label>
                    <Select
                      name="broker_id"
                      options={brokerData.map((i: any) => ({
                        value: i.value, label: i.label
                      })) || []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select broker..."
                      isClearable={true}
                      onChange={(option: any) => setFieldValue('broker_id', option ? option.value : null)}
                    />
                    <ErrorMessage name="broker_id" component="div" className="text-danger"/>
                  </div>

                  <div className="mb-4">
                    <label>Select buy method</label>
                    <Select
                      name="buy_method_id"
                      options={buy_methods}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select buy method..."
                      isClearable={true}
                      onChange={(option: any) => {
                        setFieldValue('buy_method_id', option ? Number(option.value) : null)
                        if (option.value !== 1) {
                          setFieldValue('user_ids', [])
                        }
                      }}
                    />
                    <ErrorMessage name="buy_method_id" component="div" className="text-danger"/>
                  </div>

                  {values.buy_method_id === 2 && (
                    <div className="mb-4">
                      <label>Resale</label>
                      <Field name="resale" as={Input} placeholder="Resale..."/>
                      <ErrorMessage name="resale" component="div" className="text-danger"/>
                    </div>
                  )}

                  {values.buy_method_id === 1 && (
                    <div className="mb-4">
                      <label>Select teams</label>
                      <Select
                        name="user_ids"
                        isMulti
                        options={buyerData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select teams..."
                        isClearable={true}
                        isLoading={isLoadingBuyer}
                        onChange={(options: any) => setFieldValue('user_ids', options ? options.map((o: any) => o.value) : [])}
                      />
                      <ErrorMessage name="user_ids" component="div" className="text-danger"/>
                    </div>
                  )}

                  <div className="mb-4">
                    <label>Select order GEO BOX</label>
                    <Select
                      name="geo_box_id"
                      options={CountryBoxes?.map((i: any) => ({
                        label: i.name, value: i.id
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select order geo box..."
                      isClearable={true}
                      isLoading={isLoadingCountryBoxes}
                      onChange={(option: any) => setFieldValue('geo_box_id', option ? Number(option.value) : null)}
                    />
                    {errors.geo_box_id && <div className="text-danger"> {errors.geo_box_id}</div>}
                  </div>

                  <div className="mb-4">
                    <label>Select order GEO</label>
                    <Select
                      name="geo_ids"
                      isMulti
                      options={dataCountries.map((i: any) => ({
                        label: i.title, value: i.id
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select order geo..."
                      isClearable={true}
                      isLoading={loading}
                      onChange={(options: any) => setFieldValue('geo_ids', options ? options.map((o: any) => o.value) : [])}
                    />
                    {/*<ErrorMessage name="geo_ids" component="div" className="text-danger"/>*/}
                    {errors.geo_ids && <div className="text-danger"> {errors.geo_ids}</div>}
                  </div>

                  <div className="mb-4">
                    <label>Funnel name</label>
                    <Field name="funnel_name" as={Input} placeholder="Funnel name..."/>
                    <ErrorMessage name="funnel_name" component="div" className="text-danger"/>
                  </div>

                  <div className="mb-4">
                    <label>Select pay method</label>
                    <Select
                      name="pay_method_id"
                      options={pay_methods}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select pay method..."
                      isClearable={true}
                      onChange={(option: any) => {
                        setFieldValue('pay_method_id', option ? option.value : null)
                        if (option.value !== 3) {
                          setFieldValue('cr', null)
                        }
                      }}
                    />
                    <ErrorMessage name="pay_method_ids" component="div" className="text-danger"/>
                  </div>

                  <Row>
                    <div className={`mb-4 ${values.pay_method_id === 3 ? "col-8" : ""}`}>
                      <label>Payout</label>
                      <Field name="payout" as={Input} placeholder="Payout..."/>
                      <ErrorMessage name="payout" component="div" className="text-danger"/>
                    </div>
                    {values.pay_method_id === 3 && (
                      <div className="mb-4 col-4">
                        <label>CR %</label>
                        <Field name="cr" as={Input} placeholder="CR..."/>
                        <ErrorMessage name="cr" component="div" className="text-danger"/>
                      </div>
                    )}
                  </Row>

                  <div className="mb-4">
                    <label>Daily Cap</label>
                    <Field name="daily_cap" as={Input} placeholder="Daily Cap..."/>
                    <ErrorMessage name="daily_cap" component="div" className="text-danger"/>
                  </div>

                  <Row>
                    <label>Call center time</label>

                    <div className="mb-4 col-6">
                      <Label htmlFor="start-time-input" className="form-Label">Start Time</Label>
                      <Field
                        className="form-control"
                        type="time"
                        name="from_time"
                        id="start-time-input"
                        placeholder="Start Time"
                      />
                      <ErrorMessage name="from_time" component="div" className="text-danger"/>
                    </div>

                    <div className="mb-4 col-6">
                      <Label htmlFor="to-time-input" className="form-Label">End Time</Label>
                      <Field
                        className="form-control"
                        type="time"
                        name="to_time"
                        id="to-time-input"
                        placeholder="End Time"
                      />
                      <ErrorMessage name="to_time" component="div" className="text-danger"/>
                    </div>
                  </Row>

                  <button type="submit" className="btn btn-primary w-100" disabled={!isValid}>
                    Save
                  </button>

                </Form>
              );
            }}
          </Formik>
        </OffcanvasBody>
      ) : (
        <NoSearchResult text="Loading ..."/>
      )}
    </Offcanvas>
  );
};

export default CreateOrder;