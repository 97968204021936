import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import NoSearchResult from './NoSearchResult';

interface PaginationProps {
  data?: any;
  perPageData?: any;
  currentPage?: any;
  setCurrentPage?: any;
  currentData?: any;
  totalPages?: number;
  className?: any;
  total?: number;
}

const Pagination = ({
                      perPageData,
                      data,
                      currentPage,
                      setCurrentPage,
                      currentData,
                      totalPages = 1,
                      total,
                      className,
                    }: PaginationProps) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handleClick = (e: any) => {
    const page = Number(e.target.id);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages, setCurrentPage]);

  return (
    <React.Fragment>
      {!data?.length && <NoSearchResult />}
      {totalPages > 1 && (
        <Row className={className} style={{ display: 'flex' }}>
          <div className="col-sm">
            <div className="text-center text-sm-start">
              Showing page <span className="fw-normal ms-1">{currentPage}</span> of{' '}
              <span className="fw-normal">{totalPages}</span>
            </div>
          </div>
          <div className="col-sm-auto">
            <div className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">

              <Link
                className="page-link"
                to="#!"
                onClick={handlePrevPage}
              >
                Previous
              </Link>

              <ul className="pagination listjs-pagination mb-0">
                {pageNumbers.map((item) => (
                  <li className="page-item" key={item}>
                    <Link
                      className={`page-link ${currentPage === item ? 'active' : ''}`}
                      to="#!"
                      id={item.toString()}
                      onClick={handleClick}
                    >
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>

              <Link
                className="page-link"
                to="#!"
                onClick={handleNextPage}
              >
                Next
              </Link>
            </div>
          </div>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Pagination;