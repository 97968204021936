import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearTeams, getTeams, modalTeam } from "../../store/team/actions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableTeam from "./components/TableTeam";
import NoSearchResult from "../../components/Common/NoSearchResult";
import ModalTeam from "./components/Modal";
import usePermissions from "src/hooks/usePermissions";
import Pagination from "../../components/Common/Pagination";

const Teams = () => {
    document.title = "Team List | Dashboard";

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { hasPermission } = usePermissions();

    const { data = [], pagination = {}, loading = false, isOpenModal = false } = useSelector((state: any) => state.Teams);

    const [filter, setFilter] = useState({ per_page: 10, page: 1 });

    const updateFilter = (changes: Partial<typeof filter>) => {
        setFilter((prevFilter) => ({ ...prevFilter, ...changes }));
    };

    useEffect(() => {
        dispatch(getTeams("", filter));
        return () => {
            dispatch(clearTeams());
        };
    }, [dispatch, filter]);

    const handleUserClicks = () => {
        dispatch(modalTeam(true));
    };

    return (
      <div className="page-content">
          <Container fluid>
              <Breadcrumbs title="Dashboard" breadcrumbItem="Teams" />

              {hasPermission("create-teams") && (
                <Row className="align-items-center mb-3">
                    <Col className="text-end">
                        <Link to="#" className="btn btn-light" onClick={handleUserClicks}>
                            <i className="bx bx-plus me-1"></i> {t("Add New")}
                        </Link>
                    </Col>
                </Row>
              )}

              <Row>
                  <Col lg="12">
                      <Card>
                          <CardBody className="border-bottom">
                              {loading ? (
                                <NoSearchResult />
                              ) : data.length > 0 ? (
                                <>
                                    <TableTeam
                                      data={data}
                                      pageSize={filter.per_page}
                                      currentPage={filter.page}
                                      totalPages={pagination.total_pages}
                                      onPageSizeChange={(newPageSize: number) =>
                                        updateFilter({ per_page: newPageSize, page: 1 })
                                      }
                                      onPageChange={(newPage: number) => updateFilter({ page: newPage })}
                                    />
                                    <Pagination
                                      data={data}
                                      currentPage={pagination.current_page}
                                      perPageData={pagination.per_page}
                                      setCurrentPage={(page: number) => updateFilter({ page })}
                                      currentData={data.slice(
                                        (pagination.current_page - 1) * pagination.per_page,
                                        pagination.current_page * pagination.per_page
                                      )}
                                      totalPages={pagination.total_pages}
                                      className="custom-pagination"
                                      total={pagination.total}
                                    />
                                </>
                              ) :
                                 loading ? ( <NoSearchResult /> ) : null
                              }
                          </CardBody>
                      </Card>
                  </Col>
              </Row>
          </Container>

          {isOpenModal && <ModalTeam isOpen={isOpenModal} />}
      </div>
    );
};

export default Teams;