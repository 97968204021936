import { del, get, post, put } from "../helpers/api_helper"

export const TrafficOrderService = {

  getTrafficOrders: async (params?: any) => {
    const queryString = params ? `?${new URLSearchParams(params).toString()}` : '';
    return get(`/traffic-orders${queryString}`)
  },
  getTrafficOrdersWaiting: async (params?: any) => {
    const queryString = params ? `?${new URLSearchParams(params).toString()}` : '';
    return get(`/traffic-orders/waiting${queryString}`)
  },
  getTrafficOrdersNotActive: async (params?: any) => {
    const queryString = params ? `?${new URLSearchParams(params).toString()}` : '';
    return get(`/traffic-orders/not_active${queryString}`);
  },

  getTrafficOrder: async (id: number) => {
    return get(`/traffic-orders/${id}`)
  },

  getStatusTrafficOrder: async (id: number, date: string) => {
    return get(`/traffic-orders/${id}/get_status`, {
      params: { date },
    });
  },

  createTrafficOrder: async (data: any) => {
    return post('/traffic-orders', data)
  },

  getResponsibilityTrafficOrder: async (id: number) => {
    return get(`/traffic-orders/${id}/responsibility`)
  },

  assignTrafficOrder: async (data: any, id: number) => {
    return post(`/traffic-orders/${id}/responsibility`, data)
  },

  getBrokerTrafficOrder: async (id: number) => {
    return get(`/traffic-orders/${id}/broker`)
  },

  updateBrokerTrafficOrder: async (data: any, id: number) => {
    return post(`/traffic-orders/${id}/broker`, data)
  },

  updateStatusTrafficOrder: async (data: any, id: number) => {
    return post(`/traffic-orders/${id}/status`, data)
  },

  updateTrafficOrder: async (id: number, data: any) => {
    return put(`/traffic-orders/${id}`, data)
  },

  pinnedTrafficOrder: async (id: number) => {
    return post(`/traffic-orders/${id}/toggle-pin`, {})
  },

}