import React, {useEffect, useMemo, useRef, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import { useDispatch } from "react-redux";
import { delTeam, getTeam } from "../../../store/team/actions";
import usePermissions from "src/hooks/usePermissions";
import BootstrapTable from "react-bootstrap-table-next";

const TableTeam = React.memo((props: any) => {
  const {
    data,
    pageSize,
    onPageSizeChange,
    customPageSize
  } = props;

  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();

  const [deleteModal, setDeleteModal] = useState(false);
  const [contact, setContact] = useState<any>();

  const onClickUpdate = (arg: any) => {
    dispatch(getTeam(arg.id));
  };

  const teams = useMemo(
    () =>
      data.map((item: any) => ({
        id: item.id.toString() || null,
        name: item.name || null,
        created_at: moment(item.created_at).format("YYYY-MM-DD") || null,
      })).filter((item: any) => item !== null),
    [data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        disableFilters: true,
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        disableFilters: true,
        sortable: true,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        sortable: true,
      },
      {
        Header: "Action",
        Cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-3">
              {hasPermission("update-teams") && (
                <Link
                  className="text-success"
                  to="#"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickUpdate(userData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18"></i>
                </Link>
              )}
              {hasPermission("delete-teams") && (
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => {
                      const team = cellProps.row.original;
                      onClickDelete(team);
                    }}
                  ></i>
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    [hasPermission]
  );

  const onClickDelete = (arg: any) => {
    setContact(arg);
    setDeleteModal(true);
  };

  const handleDeleteTeam = () => {
    dispatch(delTeam(contact.id));
    setDeleteModal(false);
  };

  return (
    <>
      <div style={{ borderCollapse: 'collapse', borderSpacing: '0 8px', width: '100%' }}>
      <TableContainer
        columns={columns}
        data={teams}
        isGlobalFilter={true}
        customPageSize={pageSize}
        className="table align-middle datatable dt-responsive table-check nowrap"
        onPageSizeChange={onPageSizeChange}
      />
      </div>

      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteTeam}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
    </>
  );
});
TableTeam.displayName = "TableTeam";
export default TableTeam;