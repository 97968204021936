import React, { createContext, useState } from "react";

type FilterType = {
  per_page: number;
  page: number;
};

type FilterContextType = {
  filter: FilterType;
  setFilter: (newFilter: Partial<FilterType>) => void;
};

export const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filter, setFilterState] = useState<FilterType>({ per_page: 10, page: 1 });

  const setFilter = (newFilter: Partial<FilterType>) => {
    setFilterState((prev) => {
      const updatedFilter = { ...prev, ...newFilter };
      if (JSON.stringify(prev) !== JSON.stringify(updatedFilter)) {
        return updatedFilter;
      }
      return prev;
    });
  };

  return (
    <FilterContext.Provider value={{ filter, setFilter }}>
      {children}
    </FilterContext.Provider>
  );
};